import React, { useContext, useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye } from "react-icons/fa";
import {
  IconButton,
  Button,
  Typography,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import DummyImage from "../assets/images/dummy.jpg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const ReceiptTable = (props) => {
  const axiosInstance = useAxiosConfig();
  const headerData = props.headerData;
  const { receiptData, setReceiptData } = useContext(AuthContext);
  const { receiptPagination, setReceiptPagination } = useContext(AuthContext);

  const [openImageDialog, setOpenImageDialog] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: "", severity: "info" });

  const handleChange = async (event, value) => {
    setReceiptPagination({
      currentPage: value,
      totalPages: receiptPagination.totalPages,
      totalCounts: receiptPagination.totalCounts,
    });

    try {
      const response = await axiosInstance.get(
        `api/admin/payments/view-all-recipts?page=${value}`
      );
      setReceiptData(response.data.receipts);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCloseNotification = () => {
    setNotification({ open: false, message: "", severity: "info" });
  };  

  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    try {
      const body = { receiptId: deleteItemId };
      const response = await axiosInstance.post(
        "api/admin/payments/reject-request",
        body
      );
  
      const updatedReceipts = receiptData.map((receipt) => {
        if (receipt._id === deleteItemId) {
          return { ...receipt, status: response.data.receipt.status };
        }
        return receipt;
      });
  
      setReceiptData(updatedReceipts);
      setNotification({ open: true, message: "Receipt rejected successfully.", severity: "success" });
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error("Error deleting data:", error);
  
      if (error.response?.data?.message === "Receipt already rejected") {
        setNotification({ open: true, message: "Receipt is already rejected.", severity: "warning" });
        setIsDeleteModalOpen(false);
      } else {
        setNotification({ open: true, message: "Error deleting data.", severity: "error" });
      }
      setIsDeleting(false);
    }
  };
  

  const handleEyeClick = (image) => {
    setLoading(true);
    setSelectedImage(image || DummyImage);
    setOpenImageDialog(true);
  };

  const handleCloseImageDialog = () => {
    setOpenImageDialog(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    if (selectedImage) {
      const img = new Image();
      img.src = `https://maidan-s3.s3.us-west-2.amazonaws.com/${selectedImage}`;
      img.onload = () => setLoading(false);
      img.onerror = () => setLoading(false);
    }
  }, [selectedImage]);

  const getStatusStyle = (status) => {
    switch (status) {
      case "Rejected":
        return { color: "red" };
      case "Approved":
        return { color: "green" };
      default:
        return {};
    }
  };

  return (
    <>
      <Snackbar
        open={notification.open}
        autoHideDuration={5000}
        onClose={handleCloseNotification}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
      <TableContainer
        component={Paper}
        sx={{
          marginX: "auto",
          maxWidth: {
            sm: "100%",
            md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "80vh",
            sm: "90vh",
          },
          overflowY: "auto",
          position: "relative",
          top: "8rem",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {receiptData.map((data) => (
              <TableRow
                key={data._id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">{data.userId.fullName}</TableCell>
                <TableCell align="left">{data.amount}</TableCell>
                <TableCell align="left" style={getStatusStyle(data.status)}>
                  {data.status}
                </TableCell>
                <TableCell align="left">{data.userId.phone_no}</TableCell>
                <TableCell align="center">
                  <IconButton
                    onClick={() => handleEyeClick(data.transactionImage)}
                  >
                    <FaEye
                      style={{
                        cursor: "pointer",
                        fontSize: 24,
                        color: "green",
                      }}
                    />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteClick(data._id)}>
                    <DeleteForeverIcon
                      style={{
                        cursor: "pointer",
                        fontSize: 24,
                        color: "red",
                      }}
                    />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className="text-red-700">Confirm Rejection!</h2>
            {isDeleting ? (
              <p className="sm:text-sm">Rejecting...</p>
            ) : (
              <p className="sm:text-sm">
                Are you sure you want to reject this receipt?
              </p>
            )}
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleConfirmDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Rejecting..." : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
                disabled={isDeleting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}

      <div
        style={{
          position: "absolute",
          bottom: 0,
          display: "flex",
          justifyContent: "center",
          width: "100%",
          marginBottom: "0.5rem",
        }}
      >
        <Pagination
          count={receiptPagination.totalPages}
          page={receiptPagination.currentPage}
          onChange={handleChange}
        />
      </div>

      <Dialog
        onClose={handleCloseImageDialog}
        open={openImageDialog}
        sx={{ minWidth: "300px" }}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "300px",
          }}
        >
          Receipt
          <IconButton
            aria-label="close"
            onClick={handleCloseImageDialog}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress />
            </div>
          ) : (
            <img
              src={`https://maidan-s3.s3.us-west-2.amazonaws.com/${selectedImage}`}
              alt={`Image ${selectedImage}`}
              style={{ width: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseImageDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReceiptTable;
