// PlayerProfileModal.js
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Divider, IconButton } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import useAxiosConfig from "../config/AxiosConfig";

const formatDate = (dateString) => {
  const options = { year: "numeric", month: "long", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

const formatTime = (timeString) => {
  // Check if the timeString is in a valid ISO 8601 format
  const iso8601Regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;
  if (!iso8601Regex.test(timeString)) {
    // Handle invalid time format
    return "Invalid Time";
  }

  const date = new Date(timeString);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");

  return `${hours}:${minutes}`;
};

const MatchProfileModal = ({ match, handleClose }) => {
  const axiosInstance = useAxiosConfig();
  // Add conditional checks to ensure properties
  const organizer = match.organizer || {};
  const teamOne = match.Team1 || {};
  const teamTwo = match.Team2 || {};
  const matchID = match._id;

  const [postMatchAnalysis, setPostMatchAnalysis] = useState([]);
  const TeamOneStats = postMatchAnalysis.TeamOneStats || {};
  const TeamTwoStats = postMatchAnalysis.TeamTwoStats || {};
  const abc = TeamOneStats.stats;
  const xyz = TeamTwoStats.stats;

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get(
          `https://api.maidanofficial.com/api/admin/matchanalysis/${matchID}`
        );
        if (response.data.status) {
          setPostMatchAnalysis(response.data);
        } else {
          console.log("Error While Fetching the Data");
        }
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [matchID]);

  const calculateRating = (ratings) => {
    if (!Array.isArray(ratings) || ratings.length === 0) {
      return null; // or some default value if no ratings are available
    }

    const totalRating = ratings.reduce(
      (total, rating) => total + parseFloat(rating),
      0
    );
    const averageRating = totalRating / ratings.length;

    return parseFloat(averageRating.toFixed(1));
  };

  return (
    <Dialog open={!!match} onClose={handleClose} maxWidth="lg" fullWidth>
      <DialogTitle sx={{ textAlign: "center" }}>Match Profile</DialogTitle>
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "2rem",
          // flexWrap: "wrap",
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", gap: "3rem" }}
        >
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>Match ID:</strong> {match._id}
            </div>
            <div>
              <strong>Match Type:</strong> {match.matchType}
            </div>
            <div>
              <strong>Date</strong> {formatDate(match.date)}
            </div>
            <div>
              <strong>Created At</strong> {formatTime(match.createdAt)}
            </div>

            <div>
              <strong>Start Time</strong> {formatTime(match.startTime)}
            </div>
            <div>
              <strong>End Time</strong> {formatTime(match.endTime)}
            </div>
            <div>
              <strong>Status</strong> {match.status}
            </div>
            <div>
              <strong>promoCode</strong> {match.promoCode}
            </div>
            <div>
              <strong>IsDeleted</strong>{" "}
              {match.isDeleted ? (
                <IconButton sx={{ color: "green" }}>
                  <DoneOutlineIcon />
                </IconButton>
              ) : (
                <IconButton sx={{ color: "red" }}>
                  <ClearOutlinedIcon fontSize="medium" />
                </IconButton>
              )}
            </div>
          </div>

          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>Organizer Name</strong> {organizer.fullName}
            </div>

            <div>
              <strong>Organzier Id:</strong> {organizer._id}
            </div>
            <div>
              <strong>Subsitute:</strong> {match.subsitue}
            </div>
            <div>
              <strong>Pay For Team</strong> {match.payForTeam}
            </div>
            <div>
              <strong>Price:</strong> {match.price}
            </div>
            <div>
              <strong>Duration</strong> {match.duration}
            </div>
            <div>
              <strong>Squad Each</strong> {match.squadEach}
            </div>
            <div>
              <strong>Reserve Players</strong>
            </div>
            {match.reservList.map((reservePlayer) => (
              <div key={reservePlayer._id}>
                <div>
                  <strong>ID:</strong> {reservePlayer._id}
                </div>
                <div>
                  <strong>Name:</strong> {reservePlayer.fullName}
                </div>
              </div>
            ))}
          </div>
          {/* Team 1 section */}
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>TeamOne</strong>{" "}
            </div>
            <div>
              <strong>Id:</strong> {teamOne._id}
            </div>
            <div>
              <strong>Team Members</strong>
            </div>
            {teamOne.teamMember.map((teamMember) => (
              <>
                <div key={teamMember._id}>
                  <strong>Player Id:</strong> {teamMember._id}
                </div>
                <div>
                  <strong>Full Name:</strong> {teamMember.fullName}
                </div>
              </>
            ))}
          </div>
          {/* Team2 section  */}
          <div
            style={{
              flex: "1",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong>TeamTwo</strong>{" "}
            </div>
            <div>
              <strong>Id:</strong> {teamTwo._id}
            </div>
            <div>
              <strong>Team Members</strong>
            </div>
            {teamTwo.teamMember.map((teamMember) => (
              <>
                <div key={teamMember._id}>
                  <strong>Player Id:</strong> {teamMember._id}
                </div>
                <div>
                  <strong>Full Name:</strong> {teamMember.fullName}
                </div>
              </>
            ))}
          </div>
        </div>
        <div>
          <div style={{ textAlign: "center", marginBottom: "1rem" }}>
            <DialogTitle sx={{ textAlign: "center" }}>Post Match Analysis</DialogTitle>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "space-evenly",
              gap: "1rem",
              alignItems: "center",
              marginBottom: "1rem",
            }}
          >
            <div>
              <strong style={{
                paddingBottom: 2,        
              }}>Team One Goals</strong> {TeamOneStats.totalGoals}
              <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                // alignItems: "",
                marginBottom: "1rem",
              }}
            >
              <div>
                <strong>TeamOneStats</strong>
              </div>
              <div>
                <strong>Team Members</strong>
              </div>

              {/* Team 1 Stats  */}
              {abc &&
                abc.map((teamMember) => (
                  <div key={teamMember._id}>
                    <div>
                      <strong>Player Name:</strong>{" "}
                      {teamMember.player?.fullName || "N/A"}
                    </div>
                    <div>
                      <strong>Rating:</strong>{" "}
                      {teamMember.stats[0]?.rating
                        ? calculateRating(teamMember.stats[0].rating)
                        : "N/A"}
                    </div>
                  </div>
                ))}
              <div>
                <strong>Most Valuable Player:</strong>{" "}
                {TeamOneStats.mvp?.player?.fullName || "N/A"}
              </div>
              <div>
                <strong>Rating:</strong>{" "}
                {TeamOneStats.mvp?.stats[0]?.rating
                  ? calculateRating(TeamOneStats.mvp.stats[0].rating)
                  : "N/A"}
              </div>
            </div>
            </div>

            <div>
              <strong>Team Two Goals</strong> {TeamTwoStats.totalGoals}
              <div
              style={{
                flex: "1",
                display: "flex",
                flexDirection: "column",
                // alignItems: "flex-start",
                marginBottom: "1rem",
              }}
            >
              <div>
                <strong>TeamTwoStats</strong>
              </div>
              <div>
                <strong>Team Members</strong>
              </div>
              {xyz &&
                xyz.map((teamMember) => (
                  <div key={teamMember._id}>
                    <div>
                      <strong>Player Name:</strong>{" "}
                      {teamMember.player?.fullName || "N/A"}
                    </div>
                    <div>
                      <strong>Rating:</strong>{" "}
                      {calculateRating(teamMember.stats[0].rating)}
                    </div>
                  </div>
                ))}
              <div>
                <strong>Most Valuable Player:</strong>{" "}
                {TeamTwoStats.mvp?.player?.fullName || "N/A"}
              </div>
              <div>
                <strong>Rating:</strong>{" "}
                {TeamTwoStats.mvp?.stats[0]?.rating
                  ? calculateRating(TeamTwoStats.mvp.stats[0].rating)
                  : "N/A"}
              </div>
            </div>
            </div>

            {/* <div>
                          <strong>Winner</strong> {winner}
                      </div> */}
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MatchProfileModal;
