import React, { useState, useContext, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { FaEye, FaTrash, FaRegEdit } from "react-icons/fa";
import { IconButton, MenuItem, Pagination, TextField } from "@mui/material";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { AuthContext } from "../auth/ContextAuthentication";
import Button from "@mui/material/Button";
import GroundProfileModal from "./GroundProfileModal";
import useAxiosConfig from "../config/AxiosConfig";
import EditGroundModal from "./EditGroundModal";
import CircleLoader from "./CircleLoader";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const GroundTable = (props) => {
  const axiosInstance = useAxiosConfig();
  const headerData = props.headerData;
  const { groundData, setGroundData } = useContext(AuthContext);
  const [filteredData, setFilteredData] = useState(groundData); // State to store filtered data
  const [searchQuery, setSearchQuery] = useState(""); // State to manage search input
  const [cityFilter, setCityFilter] = useState("All"); // State to manage status filter
  const [selectedGround, setSelectedGround] = useState(null); // Track the selected Ground
  const [deleteItemId, setDeleteItemId] = useState(null); // Track the item ID for deletion
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // Track if the delete confirmation modal is open
  const [isDeleting, setIsDeleting] = useState(false); // Track if deletion is in progress
  const [editGround, setEditGround] = useState(null);
  const {groundPagination , setGroundPagination} = useContext(AuthContext);
  const [editManagersName, setEditManagersName] = useState([]);
  const { loading } = useContext(ErrorLoadingContext);

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle status filter change
  const handleCityFilterChange = (event) => {
    setCityFilter(event.target.value);
  };

  // Function to open the modal with ground details
  const handleViewClick = (ground) => {
    setSelectedGround(ground);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedGround(null);
  };

  // Function to open the delete confirmation modal
  const handleDeleteClick = (itemId) => {
    setDeleteItemId(itemId);
    setIsDeleteModalOpen(true);
  };

  // Function to close the delete confirmation modal
  const handleCloseDeleteModal = () => {
    setDeleteItemId(null);
    setIsDeleteModalOpen(false);
  };

  const handleEditClick = (data) => {
    setEditGround(data);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setEditGround(null);
  };

  const handleChange = async (event, value) => {
    setGroundPagination(() => ({
      currentPage: value,
      totalPages: groundPagination.totalPages,
    }));
  
    try {
      const response = await axiosInstance.get(`api/admin/getGrounds?page=${value}`);
      if (response.status === 200) {
        setGroundData(response.data.grounds);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };  

  // Function to handle the actual deletion
  const handleDelete = async () => {
    if (deleteItemId) {
      try {
        setIsDeleting(true); // Start the deletion process

        // Perform the deletion action using the deleteItemId
        await axiosInstance.put(`api/admin/deleteground/${deleteItemId}`);

        // Once the deletion is successful, update the GroundData state accordingly
        const response = await axiosInstance.get("api/admin/getgrounds");
        const updatedGroundData = response.data.grounds;

        setGroundData(updatedGroundData);

        // Close the delete confirmation modal
        handleCloseDeleteModal();
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeleting(false); // End the deletion process
      }
    }
  };

  const updateGroundData = async (updatedGround) => {};

  useEffect(() => {
    const getManagers = async () => {
      try {
        const response = await axiosInstance.get("api/ground/managers");
        setEditManagersName(response?.data?.managers);
      } catch (error) {
        console.error("Error adding ground:", error);
      }
    };

    getManagers();
  }, [setEditManagersName]);

  // Effect to filter the ground data based on search and filter inputs
  useEffect(() => {
    let filtered = groundData;

    // Apply search query
    if (searchQuery) {
      filtered = filtered.filter((ground) =>
        ground.name.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    // Apply status filter
    if (cityFilter !== "All") {
      filtered = filtered.filter((ground) => ground.city === cityFilter);
    }

    setFilteredData(filtered);
  }, [groundData, searchQuery, cityFilter]);

  return (
    <>
      {/* Search and filter */}
      <div className="absolute top-20 right-[4%] flex flex-row justify-center gap-4">
        <TextField
          type="text"
          label="Search by ground name"
          value={searchQuery}
          onChange={handleSearchInputChange}
          size="small"
        />
        <TextField
          type="text"
          value={cityFilter}
          label="City"
          size="small"
          sx={{ maxWidth: "180px" }}
          select
          onChange={handleCityFilterChange}
        >
          <MenuItem value="All">All</MenuItem>
          <MenuItem value="Islamabad">Islamabad</MenuItem>
          <MenuItem value="Lahore">Lahore</MenuItem>
        </TextField>
      </div>

      <TableContainer
        component={Paper}
        sx={{
          maxWidth: {
            sm: "100%",
            md: "80%",
          },
          minWidth: {
            sm: "500px",
          },
          maxHeight: {
            md: "80vh",
            sm: "90vh",
          },
          overflowY: "auto",
          position: "relative",
          top: "8rem",
          marginX: "auto",
        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          {loading ? (
            <CircleLoader />
          ) : (
            <TableBody>
              {filteredData.map((data) => (
                <TableRow
                  key={data._id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {data.name}
                  </TableCell>
                  <TableCell align="left">{data.noOfPlayers}</TableCell>
                  <TableCell align="left">{data.noOfFields}</TableCell>
                  <TableCell align="left">{data.dimensions}</TableCell>
                  <TableCell align="left">{data.city}</TableCell>
                  <TableCell align="left">
                    {data.isDeleted ? (
                      <IconButton sx={{ color: "green" }}>
                        <DoneOutlineIcon />
                      </IconButton>
                    ) : (
                      <IconButton sx={{ color: "red" }}>
                        <ClearOutlinedIcon fontSize="medium" />
                      </IconButton>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "5px",
                      }}
                    >
                      <IconButton onClick={() => handleViewClick(data)}>
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleEditClick(data)}>
                        <FaRegEdit
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "purple",
                          }}
                        />
                      </IconButton>
                      <IconButton onClick={() => handleDeleteClick(data._id)}>
                        <FaTrash
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "red",
                          }}
                        />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {selectedGround && (
          <GroundProfileModal
            ground={selectedGround}
            setSelectedGround={setSelectedGround}
            handleClose={handleCloseModal}
          />
        )}
        {editGround && (
          <EditGroundModal
            ground={editGround}
            handleClose={handleCloseEditModal}
            handleUpdate={updateGroundData}
            editManagersName={editManagersName}
          />
        )}
      </TableContainer>
      <div style={{position:"absolute" , bottom:0 , display:"flex" , justifyContent:"center" , width:"100%" , marginBottom:"0.5rem" }}>
      <Pagination count={groundPagination.totalPages} page={groundPagination.currentPage} onChange={handleChange} />
      </div>

      {/* Render the delete confirmation modal */}
      {isDeleteModalOpen && (
        <div
          className="max-[500px]:w-72 w-1/3 max-[500px]:relative"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            borderRadius: "5px",
            boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            padding: "20px",
            backgroundColor: "#fff",
            "@media (maxWidth: 600px)": {
              maxWidth: 250,
            },
          }}
        >
          <div className="flex flex-col gap-3">
            <h2 className="text-red-700">Confirm Deletion!</h2>
            {isDeleting ? (
              <p className="sm:text-sm">Deleting...</p>
            ) : (
              <p className="sm:text-sm">
                Are you sure you want to delete this record?
              </p>
            )}
            <div className="flex justify-center gap-3">
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={handleDelete}
                disabled={isDeleting}
              >
                {isDeleting ? "Deleting..." : "Confirm"}
              </Button>
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleCloseDeleteModal}
                disabled={isDeleting}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default GroundTable;
