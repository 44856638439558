import React, { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { IconButton, CircularProgress } from "@mui/material";
import { AuthContext } from "../auth/ContextAuthentication";


const BookingTable = ({ headerData, isLoading, isGroundSelected, isDateSelected, groundMap }) => {
    const { bookingData } = useContext(AuthContext);
  
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };
  
    const formatTime = (timeString) => {
      const date = new Date(timeString);
      return date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
        timeZone: 'UTC' 
      });
    };
  
    const getGroundName = (groundId) => groundMap?.[groundId]?.name || "N/A";
    const getFieldName = (groundId, fieldId) => groundMap?.[groundId]?.fields?.[fieldId] || "N/A";
  
    const renderTableContent = () => {
      if (isLoading) {
        return (
          <TableRow>
            <TableCell colSpan={headerData.length + 1} align="center">
              <CircularProgress />
            </TableCell>
          </TableRow>
        );
      }
    
      if (!bookingData || bookingData.length === 0) {
        return (
          <TableRow>
            <TableCell colSpan={headerData.length + 1} align="center">
              No bookings available
            </TableCell>
          </TableRow>
        );
      }

      // Sort bookingData by date
      const sortedBookingData = [...bookingData].sort((a, b) => new Date(a.date) - new Date(b.date));
    
      return sortedBookingData.map((booking) => (
        <TableRow key={booking._id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
          <TableCell sx={{ width: '150px', whiteSpace: 'nowrap', fontWeight: 'bold', fontStyle: 'italic' }}>{booking?.bookingBy}</TableCell>
          <TableCell sx={{ width: '150px', whiteSpace: 'nowrap' }}>{booking.phoneNo || "N/A"}</TableCell>
          <TableCell sx={{ width: '200px', whiteSpace: 'nowrap' }}>{formatDate(booking?.date)}</TableCell>
          <TableCell sx={{ width: '200px', whiteSpace: 'nowrap' }}>{getGroundName(booking?.ground)}</TableCell>
          <TableCell sx={{ width: '200px', whiteSpace: 'nowrap' }}>{getFieldName(booking?.ground, booking?.field)}</TableCell>
          <TableCell sx={{ width: '200px', whiteSpace: 'nowrap' }}>{`${formatTime(booking.startTime)} - ${formatTime(booking.endTime)}`}</TableCell>
          <TableCell sx={{ 
              width: '150px', 
              whiteSpace: 'nowrap', 
              fontStyle: 'italic',
              backgroundColor:
                booking.AdvanceAmount
                ? 'rgba(0, 255, 0, 0.1)'
                : 'transparent',}}>{booking?.AdvanceAmount}</TableCell>
          <TableCell sx={{ 
            width: '150px', 
            whiteSpace: 'nowrap',
            fontStyle: 'italic',
            backgroundColor:
              booking.AdvanceAmount
              ? 'rgba(255, 0, 0.1)'
              : 'transparent',
          }}>{booking.paidAmount ? (parseFloat(booking.bookingFee) - parseFloat(booking.paidAmount)).toFixed(2) : 'N/A'}</TableCell>
          <TableCell sx={{ width: '150px', whiteSpace: 'nowrap' }}>{booking.promoCode || "N/A"}</TableCell>
        </TableRow>
      ));
    };
    
  
    return (
      <TableContainer
      component={Paper}
      sx={{
        maxWidth: {
          sm: "100%",
          md: "100%",
        },
        minWidth: {
          sm: "500px",
        },
        maxHeight: {
          md: "75vh",
          sm: "90vh",
        },
        overflowY: "auto",
        position: "relative",
        // top: "8rem",
        marginX: "auto",
      }}
      >
        <Table aria-label="booking table">
          <TableHead
            sx={{
              position: "sticky",
              top: 0,
              backgroundColor: "#ffff",
              zIndex: "1",
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell key={index} sx={{ fontWeight: 'bold', widht: '150px', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderTableContent()}</TableBody>
        </Table>
      </TableContainer>
    );
};
  
  

export default BookingTable;