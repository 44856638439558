

import React, { useContext, useEffect, useState } from "react";
import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import PaymentTable from "../component/PaymentTable"; 
import ReceiptTable from "../component/ReceiptTable";

const Receipts = () => {
  const axiosInstance = useAxiosConfig();
  const { setReceiptData } = useContext(AuthContext);
  const { setReceiptPagination} = useContext(AuthContext)
  const [ datafetched, setDataFetched] = useState(false)
  const headerData = [
   
    "FullName",
    "Amount",
    "Status",
    "Phone Number"
  ];

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get("api/admin/payments/view-all-recipts");
        
        setReceiptData(response.data.receipts);
        const {  currentPage ,  totalPages  , totalCounts} = response.data
        
        setReceiptPagination({
        //   "limit" : limit,
          "currentPage":currentPage,
        //   "status":status,
          "totalPages" : totalPages,
          "totalCounts":totalCounts
        })
        setDataFetched(true)
        
        
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [setReceiptData ,setReceiptPagination]);
 
  return (
    <>
      {/* <TableData headerData={headerData} /> */}
      {datafetched && <ReceiptTable headerData={headerData}/>}
      
    </>
  );
};

export default Receipts;
