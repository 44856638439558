import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Snackbar,
  Alert,
  Box,
  Select,
  MenuItem,
} from "@mui/material";
import useAxiosConfig from "../config/AxiosConfig";
const AddGroundModal = ({
  showModal,
  handleClose,
  handleSave,
  newGroundData,
  handleInputChange,
  setNewGroundData,
  managersName,
  setManagersName,
}) => {
  const axiosInstance = useAxiosConfig();
  const [addStatus, setAddStatus] = useState(null);
  const [updatedMembers, setUpdatedMembers] = useState([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  useEffect(() => {
    if (managersName && managersName?.length > 0) {
      const updatedManagers = managersName.filter(
        (manager) => manager.fullName !== undefined
      );
      setUpdatedMembers(updatedManagers);
    }
  }, [managersName]);

  const handleImageUpload = async (e) => {
    const imageFile = e.target.files[0];

    if (!imageFile) return;

    try {
      const formData = new FormData();
      formData.append("img", imageFile);
      // console.log(formData);

      // Send a POST request to the image upload API
      const response = await axiosInstance.post("api/upload/image", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      // Get the uploaded image URL from the response and update newGroundData
      const imageUrl = response.data.img_url;
      // console.log(imageUrl);

      // Use setNewGroundData from props to update the state in GroundProfile component
      setNewGroundData((prevData) => ({
        ...prevData,
        images: [...prevData.images, imageUrl], // Assuming images is an array
      }));

      // Optionally, display a success message
      setAddStatus({ message: "Image uploaded successfully", success: true });
    } catch (error) {
      console.error("Error uploading image:", error);
      setAddStatus({ message: "Failed to upload image", success: false });
    }
  };

  const handleAddClick = async () => {
    let coordinates = [];
    parseInt(longitude);
    parseInt(latitude);

    coordinates.push(longitude);
    coordinates.push(latitude);
    let data = {};
    data = { ...newGroundData, coordinates };

    try {
      // Send a POST request to save the new ground data
      const response = await axiosInstance.post("api/admin/createGround", data);
      // Call the callback function to handle saving and updating data
      handleSave();
      // Set a success message
      setAddStatus({ message: "Ground added successfully", success: true });
    } catch (error) {
      console.error("Error adding ground:", error);
      setAddStatus({
        message: "Failed to add ground, some fields are empty",
        success: false,
      });
    }
  };

  useEffect(() => {
    const getManagers = async () => {
      try {
        // Send a POST request to save the new ground data
        const response = await axiosInstance.get("api/ground/managers");

        setManagersName(response?.data?.managers);
      } catch (error) {
        console.error("Error getting managers:", error);
      }
    };

    getManagers();
  }, [setManagersName]);

  return (
    <Dialog
      open={showModal}
      onClose={handleClose}
      maxWidth="lg"
      //   fullWidth
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <DialogTitle>Add New Ground</DialogTitle>
      <DialogContent>
        <form>
          {/* Render ground input fields */}

          <Select
            // required
            id="owner"
            name="owner"
            value={newGroundData.owner}
            onChange={handleInputChange}
            displayEmpty
            inputProps={{ "aria-label": "Without label" }}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
              width: "19.5%",
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 200, // Set your desired max height
                },
              },
            }}
          >
            <MenuItem value={newGroundData.owner} disabled>
              {"Select Ground Owner"}
            </MenuItem>
            {updatedMembers.map((manager) => (
              <MenuItem key={manager._id} value={manager._id}>
                {manager.fullName}
              </MenuItem>
            ))}
          </Select>

          <TextField
            required
            id="name"
            name="name"
            label="Ground Name"
            variant="outlined"
            value={newGroundData.name}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="durationDay"
            name="durationDay"
            label="Duration Day"
            variant="outlined"
            type="number"
            value={newGroundData.durationDay}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="durationNight"
            name="durationNight"
            label="Duration Night"
            variant="outlined"
            type="number"
            value={newGroundData.durationNight}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px",
            }}
          />

          <TextField
            required
            id="pricePerBookingDay"
            name="pricePerBookingDay"
            label="Price per Booking Day"
            variant="outlined"
            type="number"
            value={newGroundData.pricePerBookingDay}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="pricePerBookingNight"
            name="pricePerBookingNight"
            label="Price per Booking Night"
            variant="outlined"
            type="number"
            value={newGroundData.pricePerBookingNight}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="perPlayer"
            name="perPlayer"
            label="Per Player"
            variant="outlined"
            type="number"
            value={newGroundData.perPlayer}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="noOfPlayers"
            name="noOfPlayers"
            label="No Of Players"
            variant="outlined"
            type="number"
            value={newGroundData.noOfPlayers}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="noOfFields"
            name="noOfFields"
            label="No Of Fields"
            variant="outlined"
            type="number"
            value={newGroundData.noOfFields}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="dimensions"
            name="dimensions"
            label="dimensions"
            variant="outlined"
            type="text"
            value={newGroundData.dimensions}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="floodLights"
            name="floodLights"
            label="floodLights"
            variant="outlined"
            type="text"
            value={newGroundData.floodLights}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="restRooms"
            name="restRooms"
            label="restRooms"
            variant="outlined"
            type="text"
            value={newGroundData.restRooms}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="parkingArea"
            name="parkingArea"
            label="parkingArea"
            variant="outlined"
            type="text"
            value={newGroundData.parkingArea}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="securityCameras"
            name="securityCameras"
            label="securityCameras"
            variant="outlined"
            type="text"
            value={newGroundData.securityCameras}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="seatingArea"
            name="seatingArea"
            label="seatingArea"
            variant="outlined"
            type="text"
            value={newGroundData.seatingArea}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="footballs"
            name="footballs"
            label="footballs"
            variant="outlined"
            type="text"
            value={newGroundData.footballs}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="bibs"
            name="bibs"
            label="bibs"
            variant="outlined"
            type="text"
            value={newGroundData.bibs}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />

          <TextField
            required
            id="address"
            name="address"
            label="Address"
            variant="outlined"
            type="text"
            value={newGroundData.address}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="location"
            name="location"
            label="Location"
            variant="outlined"
            type="text"
            value={newGroundData.location}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <TextField
            required
            id="city"
            name="city"
            label="City"
            variant="outlined"
            type="text"
            value={newGroundData.city}
            onChange={handleInputChange}
            sx={{
              "& label.Mui-focused": {
                color: "#0C7240",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "#0C7240",
              },
              margin: "10px ",
            }}
          />
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                id="longitude"
                name="coordinates"
                label="Longitude"
                variant="outlined"
                type="number"
                value={longitude}
                onChange={(e) => setLongitude(e.target.value)}
                sx={{
                  "& label.Mui-focused": {
                    color: "#0C7240",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#0C7240",
                  },
                  margin: "10px ",
                }}
              />
              {longitude > 99 ? (
                <span
                  style={{ marginLeft: "10px", fontSize: "10px", color: "red" }}
                >
                  Please enter a valid longitude between -99 and 99
                </span>
              ) : (
                ""
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <TextField
                required
                id="latitude"
                name="coordinates"
                label="Latitude"
                variant="outlined"
                type="number"
                value={latitude}
                onChange={(e) => setLatitude(e.target.value)}
                sx={{
                  "& label.Mui-focused": {
                    color: "#0C7240",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "#0C7240",
                  },
                  margin: "10px ",
                }}
              />
              {latitude > 99 ? (
                <span
                  style={{ marginLeft: "10px", fontSize: "10px", color: "red" }}
                >
                  Please enter a valid latitude between -99 and 99
                </span>
              ) : (
                ""
              )}
            </div>
          </div>

          <br />
          <input
            type="file"
            accept="image/*"
            className="ml-2"
            onChange={handleImageUpload}
          />
          {/* <TextField
required
id="images"
name="images"
label="Images"
variant="outlined"
type="text"
value={newGroundData.images}
onChange={handleInputChange}
sx={{
    '& label.Mui-focused': {
        color: '#0C7240',
    },
    '& .MuiInput-underline:after': {
        borderBottomColor: '#0C7240',
    },
    margin: '10px ',
}}
/> */}

          {/* Add more fields as needed */}
        </form>
      </DialogContent>
      <DialogActions
        sx={{ justifyContent: "center", gap: "10px", padding: "10px" }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleAddClick}
          disabled={addStatus !== null}
        >
          {addStatus !== null ? "Adding..." : "Add Ground"}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleClose}
          style={{ marginLeft: "8px" }}
        >
          Close
        </Button>
      </DialogActions>
      {/* Snackbar for displaying the add status message */}
      {addStatus !== null && (
        <Box>
          <Snackbar
            open={true}
            autoHideDuration={5000}
            onClose={() => setAddStatus(null)}
          >
            <Alert
              severity={addStatus.success ? "success" : "error"}
              onClose={() => setAddStatus(null)}
            >
              {addStatus.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Dialog>
  );
};

export default AddGroundModal;
