// authContext.js
import React, { createContext, useEffect, useState } from 'react';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [adminMail, setAdminMail] = useState('');
  const [forgetPassword, setforgetPassword] = useState(false)
  const [beingSend, setBeingSend] = useState(false);
  const [open, setOpen] = useState(false);
  const [accessToken, setAccessToken] = useState('')
  const [playerData, setPlayerData] = useState([])
  const [playerPagination, setPlayerPagination] = useState({
    page: 1,
    limit: 10,
    totalPages: 1,
    totalUsers: 0,
  });
  const [groundPagination, setGroundPagination] = useState({
    currentPage: 1,
    limit: 10,
    totalPages: 1,
    totalGrounds: 0,
  });
  const [paymentTransactions , setPaymentTransactions] = useState([])
  const [paymentPagination, setPaymentPagination] = useState({
    currentPage: 1,
    // limit: 10,
    totalPages: 1,
    // totalUsers: 0,
  });


  const [receiptData , setReceiptData] = useState([])
  const [receiptPagination, setReceiptPagination] = useState({
    currentPage: 1,
    // limit: 10,
    totalPages: 1,
    totalCounts: 0,
  });
  const [groundData, setGroundData] = useState([])
  const [matchData, setMatchData] = useState([])
  
  
  useEffect(() => {
    const storedAccessToken = localStorage.getItem('access_token');
    // const accessToken = localStorage.getItem('access_token');
    if (storedAccessToken ) {
      setAccessToken(storedAccessToken);
      setAuthenticated(true);
    }
  }, []);

  

  return (
    <AuthContext.Provider value={{
      authenticated,
      setAuthenticated,
      adminMail,
      setAdminMail,
      setforgetPassword,
      forgetPassword,
      setBeingSend,
      beingSend,
      setAccessToken,
      accessToken,
      playerData,
      playerPagination,
      setPlayerData,
      setPlayerPagination,
      groundPagination,
      setGroundPagination,
      paymentTransactions,
      setPaymentTransactions,
      paymentPagination,
      setPaymentPagination,
      receiptData,
      setReceiptData,
      receiptPagination,
      setReceiptPagination,
      groundData,
      setGroundData,
      matchData,
      setMatchData,
      open,
      setOpen
      

    }}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
