import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import '../assets/css/updatePassword.css';
import useAxiosConfig from '../config/AxiosConfig';
const UpdatePassword = () => {
  const axiosInstance = useAxiosConfig();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordErrorMessage, setPasswordErrorMessage] = useState('');


  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };




  function hasEmojis(text) {
    const emojiPattern = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}/gu;
    return emojiPattern.test(text);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
    // Clear password error when the user types in the field again
    setPasswordError(false);
    setSuccessMessage('');
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    // Clear password error when the user types in the field again
    setPasswordError(false);
    setSuccessMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    
  
    if (password.trim() === '' || confirmPassword.trim() === '') {
      setPasswordError(true);
      setPasswordErrorMessage("Please fill in all the fields.");
      setTimeout(() => {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }, 3000);
      return;
    }
  
    if (password.length < 8) {
      setPasswordError(true);
      setPasswordErrorMessage("The password length should be at least 8 characters.");
      setTimeout(() => {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }, 3000);
      return;
    }
      // Check for emojis in the password
      if (hasEmojis(password)) {
        setPasswordError(true);
        setPasswordErrorMessage('Emojis are not allowed in the password.');
        setTimeout(() => {
          setPasswordError(false);
          setPasswordErrorMessage("");
        }, 3000);
        return;
        
      }
  
    if (password !== confirmPassword) {
      setPasswordError(true);
      setPasswordErrorMessage("Confirm Password does not match");
      setTimeout(() => {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }, 3000);
      return;
    }

  
    try {
      const response = await axiosInstance.post("/api/admin/updatepassword", {
        password
      });
  
      if (response.data.status === true) {
        setSuccessMessage('Password Is Updated');
        setPassword('');
        setConfirmPassword('')
        setTimeout(() => {
          setSuccessMessage('');
        }, 3000);
      } 
      if(response.data.status === false) {
        setPasswordError(true);
      setPasswordErrorMessage("Unable to Update the Password");
      setTimeout(() => {
        setPasswordError(false);
        setPasswordErrorMessage("");
      }, 3000);
      return;
      }

  
    } catch (error) {
      console.error(error);
    }
  };
  

  return (
    <>
     <div className='min-h-screen w-full flex items-center justify-center'>

      <div className="update-container">
        <form onSubmit={handleSubmit}>
          <div>
            <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-password" sx={{
                color: '#0C7240',
                fontSize: '18px',
                pointerEvents: 'none', // Disable pointer events to prevent focus behavior on label click
                '&.Mui-focused': {
                  color: '#0C7240', // Override focused label color
                },
              }}>
                Password
              </InputLabel>
              <Input
                id="standard-adornment-password"
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOff sx={{ color: '#0C7240' }} />
                      ) : (
                        <Visibility sx={{ color: '#0C7240' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  color: '#0C7240',
                  '& input': {
                    caretColor: '#0C7240',
                    '&:focus': {
                      caretColor: '#0C7240', // Custom color for caret on focus
                    },
                  },
                  '&:before': {
                    borderBottomColor: '#0C7240',
                  },
                  '&:after': {
                    borderBottomColor: '#0C7240',
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#0C7240',
                  },
                  '& .MuiIconButton-root': {
                    color: '#0C7240',
                  },
                  fontSize: "20px"
                }}
              />
            </FormControl>
          </div>
          <div>
            <FormControl sx={{ m: 1, width: '100%' }} variant="standard">
              <InputLabel htmlFor="standard-adornment-confirm-password" sx={{
                color: '#0C7240',
                fontSize: '18px',
                pointerEvents: 'none', // Disable pointer events to prevent focus behavior on label click
                '&.Mui-focused': {
                  color: '#0C7240', // Override focused label color
                },
              }}>
                Confirm Password
              </InputLabel>
              <Input
                id="standard-adornment-confirm-password"
                type={showConfirmPassword ? 'text' : 'password'}
                fullWidth
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                error={passwordError}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showConfirmPassword ? (
                        <VisibilityOff sx={{ color: '#0C7240' }} />
                      ) : (
                        <Visibility sx={{ color: '#0C7240' }} />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                sx={{
                  color: '#0C7240',
                  '& input': {
                    caretColor: '#0C7240',
                    '&:focus': {
                      caretColor: '#0C7240', // Custom color for caret on focus
                    },
                  },
                  '&:before': {
                    borderBottomColor: '#0C7240',
                  },
                  '&:after': {
                    borderBottomColor: '#0C7240',
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottomColor: '#0C7240',
                  },
                  '& .MuiIconButton-root': {
                    color: '#0C7240',
                  },
                }}
              />
            </FormControl>
          </div>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            color="primary"
            sx={{
              backgroundColor: '#0C7240',
              '&:hover': {
                backgroundColor: '#0C7240',
              },
              marginTop: "50px"
            }}
          >
            Update Password
          </Button>
          {passwordError ? (
        <div className="alert-message">
          <Alert variant="filled" severity="error" sx={{ width: '100%', textAlign: 'center' }}>
            {passwordErrorMessage}
          </Alert>
        </div>
      ) : successMessage ? (
        <div className="alert-message">
          <Alert variant="filled" severity="success" sx={{ textAlign: 'center' }}>
            {successMessage}
          </Alert>
        </div>
      ) : null}
        </form>
      </div>
      
      </div>
      


    </>
  );
};

export default UpdatePassword;
