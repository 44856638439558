import React, { useContext, useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { FaEye, FaRegEdit } from 'react-icons/fa';

import {
  IconButton,
  Button,
  CircularProgress,
  Typography,
  Alert,
  Box,
  Pagination,
} from '@mui/material';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import PlayerProfileModal from './PlayerProfileModal';
import EditUserModal from './EditUserModal';
import { AuthContext } from '../auth/ContextAuthentication';
// import TextField from '@mui/material/TextField';
import { MenuItem, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import CircleLoader from './CircleLoader';
import Snackbar from '@mui/material/Snackbar';
import useAxiosConfig from '../config/AxiosConfig';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const PaymentTable = (props) => {
  const axiosInstance = useAxiosConfig();
  const headerData = props.headerData;
  const { paymentTransactions, setPaymentTransactions } =
    useContext(AuthContext);
  const { paymentPagination, setPaymentPagination } = useContext(AuthContext);


  const handleChange = async (event, value) => {
    setPaymentPagination({
      //  "limit" : paymentTransactions.limit,
      currentPage: value,
      //  "status":paymentTransactions.status,
      totalPages: paymentPagination.totalPages,
      //  "totalUser":paymentTransactions.totalUsers
    });

    try {
      const response = await axiosInstance.get(
        `api/admin/payments/get-all-transactions?page=${value}`
      );
      setPaymentTransactions(response.data.transactions);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  };

  const handleDeleteClick = async (data) => {
    console.log('data', data);
    try {
      const body = { receiptId: '664eec273a066a88118c3386' };
      const response = await axiosInstance.post(
        'api/admin/reject-request',
        body
      );
      // setPaymentTransactions(response.data.transactions);
    } catch (error) {
      // Handle errors here
      console.error('Error fetching data:', error);
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem'  , width:"100%" , alignItems:"center" , justifyContent:"center"}}>
      <TableContainer
        component={Paper}
        sx={{
          marginX: 'auto',
          maxWidth: {
            sm: '100%',
            md: '90%',
          },
          minWidth: {
            sm: '500px',
          },
          maxHeight: {
            md: '75vh',
            sm: '90vh',
          },
          overflowY: 'auto',
         
          top: '8rem',

        }}
      >
        <Table aria-label="simple table">
          <TableHead
            sx={{
              position: 'sticky',
              top: 0,
              backgroundColor: '#ffff',
              zIndex: '1',
            }}
          >
            <TableRow>
              {headerData.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    fontWeight: 'bold',
                    border: '1px solid rgba(224, 224, 224, 1)',
                  }}
                >
                  {header}
                </TableCell>
              ))}
              {/* <TableCell align="center" sx={{ fontWeight: "bold" }}>
                Actions
              </TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentTransactions.map((data) => (
              <TableRow
                key={data._id}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },

                  textTransform: 'capitalize',
                }}
              >
                {/* <TableCell>
                    <input
                      type="checkbox"
                      checked={data.selected || false}
                      onChange={() => handleRowSelect(data._id)}
                      style={{ cursor: "pointer" }}
                    />
                  </TableCell> */}
                <TableCell
                  align="left"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {data.userId?.fullName}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                    fontWeight: 600,
                    fontStyle: 'italic',
                    backgroundColor:
                      data.type === 'debit'
                        ? 'rgba(255, 0, 0, 0.1)'
                        : 'rgba(0, 255, 0, 0.1)',
                  }}
                >
                  {data?.type}
                </TableCell>
                <TableCell
                  align="left"
                  style={{
                    border: '1px solid rgba(224, 224, 224, 1)',
                    backgroundColor:
                      data.type === 'debit'
                        ? 'rgba(255, 0, 0, 0.1)'
                        : 'rgba(0, 255, 0, 0.1)',
                  }}
                >
                  {data?.amount}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {data?.paymentType}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {data?.walletBalance}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {data.userId?.phone_no}
                </TableCell>
                <TableCell
                  align="left"
                  style={{ border: '1px solid rgba(224, 224, 224, 1)' }}
                >
                  {new Date(data?.createdAt).toLocaleDateString()}
                </TableCell>
                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {data?.matchTime ? new Date(data?.matchTime).toLocaleDateString() : ""}
                </TableCell>
                <TableCell align="left" style={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                  {data?.matchTime ? new Date(data?.matchTime).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) : ""}
                </TableCell>
                {/* <TableCell align="left">{data.city}</TableCell> */}
                {/* <TableCell align="center">  
                  <IconButton >
                        <FaEye
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "green",
                          }}
                        />
                      </IconButton>
                      <IconButton >
                        <DeleteForeverIcon
                            onClick={() => handleDeleteClick(data)}
                          style={{
                            cursor: "pointer",
                            fontSize: 24,
                            color: "red",
                          }}
                        />
                      </IconButton>
                 </TableCell> */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          width: '100%',
          marginBottom: '0.5rem',
        }}
      >
        <Pagination
          count={paymentPagination.totalPages}
          page={paymentPagination.currentPage}
          onChange={handleChange}
        />
      </div>
    </div>
  );
};
export default PaymentTable;
