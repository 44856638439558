import React, { useContext, useState } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import VerifyOtp from "./VerifyOtp";
import useAxiosConfig from "../config/AxiosConfig";

const ForgetPassword = () => {
  const axiosInstance = useAxiosConfig();
  const [email, setEmail] = useState("");
  const { beingSend, setBeingSend } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false); // Added isLoading state

  const handleSendOTP = async () => {
    try {
      setIsLoading(true); // Start loading

      const response = await axiosInstance.post("api/admin/forgotpassword", {
        email,
      });

      if (response.data.status === true) {
        setBeingSend(!beingSend);
        return;
      } else if (response.data.status === false) {
        console.log("user not found ");
        return;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false); // Stop loading regardless of success or failure
    }
  };

  return (
    <>
      {!beingSend ? (
        <div className="flex flex-col gap-6">
          <input
            type="email"
            placeholder="Enter you email"
            onChange={(event) => setEmail(event.target.value)}
            className="block w-full px-3  rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 "
          />
          <button
            style={{ backgroundColor: "#0C7240" }}
            onClick={() => handleSendOTP()}
            className="w-full rounded-md  px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            disabled={isLoading} // Disable the button while loading
          >
            {isLoading ? "Sending..." : "Send OTP"}
          </button>
        </div>
      ) : (
        <VerifyOtp email={email} />
      )}
    </>
  );
};

export default ForgetPassword;
