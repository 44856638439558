import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider, AuthContext } from './auth/ContextAuthentication';
import Login from './pages/Login';
import UpdatePassword from './pages/UpdatePassword';
import GroundProfile from './pages/GroundProfile';
import PlayersProfile from './pages/PlayersProfile';
import MatchProfile from './pages/MatchProfile';
import Dashboard from './pages/Dashboard';
import SideNav from './component/SideNav';
import TopNav from './component/TopNav';
import PaymentVerfication from './pages/PaymentVerfication';
import Receipts from './pages/Receipts';
import { ErrorLoadingProvider } from './auth/ErrorLoadingContext';
import CreatePromotion from './pages/CreatePromotion';
import ViewAllPromotions from './pages/ViewAllPromotions';
import Bookings from './pages/Bookings';

const App = () => {
  return (
    <AuthProvider>
      <ErrorLoadingProvider>
        <Router>
          <AppContent />
        </Router>
      </ErrorLoadingProvider>
    </AuthProvider>
  );
};

const AppContent = () => {
  const { authenticated } = useContext(AuthContext);

  return (
    <div className="flex">
      {/* Conditionally render SideNav and TopNav */}
      {authenticated && (
        <>
          <SideNav />

          <TopNav />
        </>
      )}
      <Routes>
        <Route path="/" element={authenticated ? <Dashboard /> : <Login />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/playersprofile"
          element={authenticated ? <PlayersProfile /> : <Login />}
        />
        <Route
          path="/groundprofile"
          element={authenticated ? <GroundProfile /> : <Login />}
        />
        <Route
          path="/bookings"
          element={authenticated ? <Bookings /> : <Login />}
        />
        <Route
          path="/updatepassword"
          element={authenticated ? <UpdatePassword /> : <Login />}
        />
        <Route
          path="/matchprofile"
          element={authenticated ? <MatchProfile /> : <Login />}
        />
        <Route
          path="/receipts"
          element={authenticated ? <Receipts /> : <Login />}
        />
        <Route
          path="/paymentverification"
          element={authenticated ? <PaymentVerfication /> : <Login />}
        />
        <Route
          path="/promotions/create"
          element={authenticated ? <CreatePromotion /> : <Login />}
        />
        <Route
          path="/promotions/view-all"
          element={authenticated ? <ViewAllPromotions /> : <Login />}
        />
      </Routes>
    </div>
  );
};

export default App;
