import React from 'react'
import CreatePromoForm from '../component/CreatePromoForm'

const CreatePromotion = () => {
  return (
    <div className='min-h-screen w-full flex items-center justify-center mt-8'>
        <CreatePromoForm />
    </div>
  )
}

export default CreatePromotion