import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../auth/ContextAuthentication";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";
import useAxiosConfig from "../config/AxiosConfig";
import BookingTable from "../component/BookingTable";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

const Bookings = () => {
  const axiosInstance = useAxiosConfig();
  const { setBookingData } = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const [grounds, setGrounds] = useState([]);
  const [selectedGround, setSelectedGround] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");
  const [groundMap, setGroundMap] = useState([]);
  const [isLoadingGrounds, setIsLoadingGrounds] = useState(false);
  const [isFetchingBookings, setIsFetchingBookings] = useState(false);

  const headerData = [
    "User's Name",
    "Phone No",
    "Booking date",
    "Ground Name",
    "Field",
    "Time Slot",
    "Advance Amount",
    "Remaining Amount",
    "Promo Code",
  ];

//   fetching grounds for autocomplete and to apply filter in bookings
useEffect(() => {
    const fetchAllGrounds = async () => {
      setIsLoadingGrounds(true);
      try {
        let allGrounds = [];
        let groundMap = {};
        let currentPage = 1;
        let hasNextPage = true;
  
        while (hasNextPage) {
          const response = await axiosInstance.get(`api/admin/getGrounds?page=${currentPage}`);
          allGrounds = [...allGrounds, ...response.data.grounds];
  
          // Populate groundMap with ground and field names
          response.data.grounds.forEach((ground) => {
            groundMap[ground._id] = {
              name: ground.name,
              fields: ground.fields.reduce((acc, field) => {
                acc[field._id] = field.name;
                return acc;
              }, {}),
            };
          });
  
          hasNextPage = currentPage < response.data.totalPages;
          currentPage++;
        }
  
        setGrounds(allGrounds);
        setGroundMap(groundMap); // Store the ground map in state
      } catch (error) {
        setError("Error fetching grounds");
      } finally {
        setIsLoadingGrounds(false);
      }
    };
  
    fetchAllGrounds();
}, []);
  

useEffect(() => {
  const fetchBookings = async () => {
    setIsFetchingBookings(true);
    try {
      let response;
      if (selectedGround && selectedDate) {
        response = await axiosInstance.get(`api/booking/new-ground-booking?groundId=${selectedGround._id}&date=${selectedDate}`);
      } else {
        // Fetch one month of bookings for all grounds if ground or date is not selected
        response = await axiosInstance.get('api/booking/new-month-booking');
      }
      setBookingData(response.data.bookings);
    } catch (error) {
      setError("Error fetching bookings");
      setBookingData([]);
    } finally {
      setIsFetchingBookings(false);
    }
  };

  fetchBookings();
}, [selectedGround, selectedDate]);

  return (
    <div className="p-4 mt-16 w-full">
      <div className="mb-4 flex gap-4">
        <Autocomplete
          options={grounds}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => (
            <TextField 
              {...params} 
              label="Select Ground" 
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoadingGrounds ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          onChange={(event, newValue) => {
            setSelectedGround(newValue);
          }}
          loading={isLoadingGrounds}
        />
        <TextField
          label="Select Date"
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}
          InputLabelProps={{ shrink: true }}
          style={{ width: 300 }}
        />
      </div>
        <BookingTable 
            headerData={headerData} 
            isLoading={isFetchingBookings} 
            isGroundSelected={!!selectedGround}
            isDateSelected={!!selectedDate}
            groundMap={groundMap}  
        />
    </div>
  );
};

export default Bookings;