import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import useAxiosConfig from "../config/AxiosConfig";

const EditUserModal = ({ user, handleClose, handleUpdate, tags }) => {
  const axiosInstance = useAxiosConfig();
  const skipAttributes = ["_id", "__v", "password", "attributes", "profile"];
  const subAttributes = user.attributes || {};
  // const skipSubAttributes = ['_id', 'user', '__v'];

  // Format the date of birth for display
  const formattedDob = user.dob
    ? new Date(user.dob).toISOString().split("T")[0]
    : "";

  // Initialize state
  const [editedUser, setEditedUser] = useState({
    ...user,
    dob: formattedDob || "", // Initialize dob as an empty string if it's null
  });

  // Initialize form fields for attributes
  const [position, setPosition] = useState(subAttributes.position || "");
  const [preferredFoot, setPreferredFoot] = useState(
    subAttributes.prefferdFoot || ""
  );
  const [age, setAge] = useState(subAttributes.age || "");
  const [height, setHeight] = useState(subAttributes.height || "");
  const [weight, setWeight] = useState(subAttributes.weight || "");

  // Initialize the selected tags state with user's tags
  const [selectedTags, setSelectedTags] = useState(user.attributes?.tags || []);

  // State for displaying update status
  const [updateStatus, setUpdateStatus] = useState(null);

  // Loading states for API requests
  const [loadingUserUpdate, setLoadingUserUpdate] = useState(false);
  const [loadingSubAttributeUpdate, setLoadingSubAttributeUpdate] =
    useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedUser((prevUser) => ({
      ...prevUser,
      ...(name.startsWith("attributes.")
        ? {
            attributes: { ...prevUser.attributes, [name.split(".")[1]]: value },
          }
        : { [name]: value }),
    }));
  };

  const handleAddTag = (tag) => {
    if (tag && !selectedTags.includes(tag)) {
      setSelectedTags([...selectedTags, tag]);
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setSelectedTags(selectedTags.filter((tag) => tag !== tagToRemove));
  };

  const handleUpdateClick = async () => {
    // Handle top-level attributes update
    setLoadingUserUpdate(true);
    try {
      const response = await axiosInstance.put(
        `api/admin/edituser/${user._id}`,
        editedUser
      );
      handleUpdate(response.data); // Update the parent state
      setUpdateStatus({ message: "Update successful", success: true }); // Set success message
    } catch (error) {
      console.error("Error updating user:", error);
      setUpdateStatus({ message: "Update failed", success: false }); // Set error message
    } finally {
      setLoadingUserUpdate(false);
    }

    // Handle sub-attributes update
    setLoadingSubAttributeUpdate(true);
    try {
      const subAttributesUpdate = {
        position,
        preferredFoot,
        age,
        height,
        weight,
        tags: selectedTags,
      };

      const response = await axiosInstance.put(
        `api/admin/editattribute/${subAttributes._id}`,
        subAttributesUpdate
      );

      handleUpdate(response.data);
    } catch (error) {
      console.error("Error updating sub-attributes:", error);
    } finally {
      setLoadingSubAttributeUpdate(false);
    }

    handleClose();
  };

  useEffect(() => {
    // Show the alert message when the component mounts
    if (updateStatus !== null) {
      // Close the alert message after 3 seconds
      const timeoutId = setTimeout(() => {
        setUpdateStatus(null);
      }, 3000);

      // Cleanup the timeout to prevent memory leaks
      return () => clearTimeout(timeoutId);
    }
  }, [updateStatus]);

  return (
    <>
      <Dialog open={!!user} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Edit User Profile</DialogTitle>
        <DialogContent>
          <form>
            {/* Render top-level attributes */}
            {Object.keys(editedUser).map(
              (attribute) =>
                !skipAttributes.includes(attribute) && (
                  <TextField
                    key={attribute}
                    label={attribute}
                    variant="outlined"
                    name={attribute}
                    value={
                      attribute === "dob"
                        ? editedUser.dob
                        : editedUser[attribute]
                    }
                    onChange={handleInputChange}
                    sx={{
                      "& label.Mui-focused": {
                        color: "#0C7240",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#0C7240",
                      },
                      margin: "10px",
                    }}
                    type={attribute === "dob" ? "date" : "text"}
                    InputLabelProps={{ shrink: true }} // Always shrink the label
                  />
                )
            )}

            {/* Render form fields for attributes */}
            <DialogTitle>Edit Attributes</DialogTitle>
            <TextField
              label="Position"
              variant="outlined"
              name="position"
              value={position}
              onChange={(e) => setPosition(e.target.value)}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px",
              }}
              type="text"
            />
            <TextField
              label="Preferred Foot"
              variant="outlined"
              name="preferredFoot"
              value={preferredFoot}
              onChange={(e) => setPreferredFoot(e.target.value)}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px",
              }}
              type="text"
            />
            <TextField
              label="Age"
              variant="outlined"
              name="age"
              value={age}
              onChange={(e) => setAge(e.target.value)}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px",
              }}
              type="text"
            />
            <TextField
              label="Height"
              variant="outlined"
              name="height"
              value={height}
              onChange={(e) => setHeight(e.target.value)}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px",
              }}
              type="text"
            />
            <TextField
              label="Weight"
              variant="outlined"
              name="weight"
              value={weight}
              onChange={(e) => setWeight(e.target.value)}
              sx={{
                "& label.Mui-focused": {
                  color: "#0C7240",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "#0C7240",
                },
                margin: "10px",
              }}
              type="text"
            />

            {/* Render tags as boxes */}
            <DialogTitle>Edit Tags</DialogTitle>
            <Box>
              {tags.map((tag) => (
                <Box
                  key={tag.name}
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    backgroundColor: selectedTags.includes(tag.name)
                      ? "green"
                      : "white",
                    color: selectedTags.includes(tag.name) ? "white" : "black",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    margin: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (selectedTags.includes(tag.name)) {
                      // Remove the tag
                      handleRemoveTag(tag.name);
                    } else {
                      // Add the tag
                      handleAddTag(tag.name);
                    }
                  }}
                >
                  {tag.name}
                  {selectedTags.includes(tag.name) && (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveTag(tag.name);
                      }}
                      style={{
                        marginLeft: "5px",
                        cursor: "pointer",
                      }}
                    >
                      &#x2716; {/* Cross symbol */}
                    </span>
                  )}
                </Box>
              ))}
            </Box>
          </form>
        </DialogContent>
        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
            padding: "10px",
          }}
        >
          <Button
            onClick={handleUpdateClick}
            color="primary"
            size="small"
            variant="contained"
            disabled={loadingUserUpdate || loadingSubAttributeUpdate}
          >
            {loadingUserUpdate || loadingSubAttributeUpdate
              ? "Updating..."
              : "Update"}
          </Button>
          <Button
            onClick={handleClose}
            color="secondary"
            size="small"
            variant="outlined"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for displaying the alert message */}
      {updateStatus !== null && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="100vh"
        >
          <Snackbar
            open={true}
            autoHideDuration={5000}
            onClose={() => setUpdateStatus(null)}
          >
            <Alert
              severity={updateStatus.success ? "success" : "error"}
              onClose={() => setUpdateStatus(null)}
            >
              {updateStatus.message}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </>
  );
};

export default EditUserModal;
