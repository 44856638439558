

import React, { useContext, useEffect, useState } from "react";
import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import PaymentTable from "../component/PaymentTable"; 

const PaymentVerfication = () => {
  const axiosInstance = useAxiosConfig();
  const { setPaymentTransactions } = useContext(AuthContext);
  const { paymentPagination , setPaymentPagination} = useContext(AuthContext)
  const [ datafetched, setDataFetched] = useState(false)
  const headerData = [
   
    "FullName",
    "Type",
    "Amount",
    "Payment Type",
    "Wallet Balance",
    "Phone Number",
    "Transaction Date",
    "Match Date",
    "Match Time",
  ];

  useEffect(() => {
    const fetchApi = async () => {
      try {
        const response = await axiosInstance.get("api/admin/payments/get-all-transactions");
        
        setPaymentTransactions(response.data.transactions);
        const {  currentPage ,  totalPages } = response.data
        
        setPaymentPagination({
        //   "limit" : limit,
          "currentPage":currentPage,
        //   "status":status,
          "totalPages" : totalPages,
        //   "totalUser":totalUsers
        })
        setDataFetched(true)
        
        
      } catch (error) {
        // Handle errors here
        console.error("Error fetching data:", error);
      }
    };

    fetchApi();
  }, [setPaymentTransactions , setPaymentPagination]);
 
  return (
    <>
      {/* <TableData headerData={headerData} /> */}
      {datafetched && <PaymentTable headerData={headerData}/>}
      
    </>
  );
};

export default PaymentVerfication;
