import React, { useContext, useEffect } from "react";
import TableData from "../component/TableData";
import { AuthContext } from "../auth/ContextAuthentication";
import useAxiosConfig from "../config/AxiosConfig";
import { ErrorLoadingContext } from "../auth/ErrorLoadingContext";

const PlayersProfile = () => {
  const axiosInstance = useAxiosConfig();
  const { setPlayerData } = useContext(AuthContext);
  const { setPlayerPagination} = useContext(AuthContext);
  const { setLoading, setError } = useContext(ErrorLoadingContext);
  const headerData = [
    "",
    "FullName",
    "Email",
    "PhoneNo",
    "Role",
    "IsSuspended",
    "IsVerified",
  ];

  useEffect(() => {
    const fetchApi = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get("api/admin/getusers");
        const { limit , page , status , totalPages ,totalUsers} = response.data
        setPlayerData(response.data.users);
        setPlayerPagination({
          "limit" : limit,
          "page":page,
          "status":status,
          "totalPages" : totalPages,
          "totalUser":totalUsers
        })
        setLoading(false);
        
      } catch (error) {
        setError("Error fetching data");
        setLoading(false);
      }
    };

    fetchApi();
  }, [setPlayerData]);

  return (
    <>
      <TableData headerData={headerData} />
    </>
  );
};

export default PlayersProfile;
