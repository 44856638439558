// ErrorLoadingContext.js
import React, { createContext, useState } from 'react';

const ErrorLoadingContext = createContext();

const ErrorLoadingProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <ErrorLoadingContext.Provider value={{
      loading,
      setLoading,
      error,
      setError
    }}>
      {children}
    </ErrorLoadingContext.Provider>
  );
};

export { ErrorLoadingContext, ErrorLoadingProvider };
