import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../auth/ContextAuthentication';
import { FaUserAlt, FaRegFutbol } from "react-icons/fa";
import { TbSoccerField } from "react-icons/tb";
import { TbBrandBooking } from "react-icons/tb";
import { ImHome } from "react-icons/im";
import { BsLockFill } from "react-icons/bs";
import { MdPassword } from "react-icons/md";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import PaymentIcon from '@mui/icons-material/Payment';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { IoCreate } from "react-icons/io5";
import { BiSolidDiscount } from "react-icons/bi";

const SideNav = () => {
    const navigate = useNavigate();
    const [openMenu, setOpenMenu] = useState(false);

    const { setAuthenticated, open, setOpen } = useContext(AuthContext);

    const handleLogout = () => {
        // Remove the access token from local storage
        localStorage.removeItem('access_token');
        // Clear the user authentication
        setAuthenticated(false);
        navigate('/login');
    };

    const handleToggle = () => {
        setOpenMenu(prevOpen => !prevOpen);
    };

    return (

            <div
            className={` ${open ? "w-20 max-[500px]:fixed max-[500px]:left-[-30%]" : "w-60 max-[500px]:fixed"
            } flex flex-col min-h-screen md:min-h-full md:h-screen lg:min-h-full lg:h-screen p-3 bg-[#0C7240] duration-300 z-20 ${
            open ? "overflow-y-auto" : "overflow-hidden"
          } fixed lg:sticky top-0`}

            >
                <div className="space-y-3 flex justify-center  flex-col">
                    <div className="flex items-center justify-between">
                        <svg width="160" height="32" viewBox="0 0 296 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M92.8042 46.8517L109.4 6.69604H121.398L122.461 22.2477C122.524 23.191 122.571 24.2003 122.599 25.2505C122.628 26.3007 122.643 27.3949 122.653 28.53C122.938 27.725 123.3 26.8603 123.738 25.9359C124.176 25.0115 124.782 23.8586 125.555 22.4772L134.447 6.69604H146.364L143.088 46.8517H133.13L135.041 27.7062C135.155 26.6277 135.284 25.5617 135.434 24.5116C135.585 23.4614 135.749 22.4018 135.934 21.3421C135.183 23.0914 134.312 24.787 133.328 26.417L132.818 27.3225L121.556 46.8517H115.009L113.289 27.3572C113.189 26.3667 113.116 25.3762 113.066 24.3795C113.016 23.3828 112.975 22.3829 112.953 21.3767C112.626 22.5653 112.291 23.6899 111.95 24.7505C111.608 25.8112 111.27 26.7985 110.934 27.7125L103.319 46.8517H92.8042Z" fill="#F6F6F6" />
                            <path d="M166.963 43.1316C165.652 44.607 164.064 45.8109 162.29 46.6753C160.612 47.477 158.776 47.8917 156.917 47.8889C153.718 47.8889 151.154 46.6805 149.226 44.2636C147.297 41.8467 146.439 38.7202 146.651 34.8842C146.908 30.063 148.511 26.0634 151.458 22.8856C154.405 19.7078 157.958 18.1168 162.117 18.1126C163.898 18.078 165.659 18.491 167.239 19.3137C168.831 20.2162 170.183 21.4888 171.179 23.0239L172.028 18.9961H181.351L175.449 46.8545H166.293L166.963 43.1316ZM156.615 33.9189C156.52 35.7007 156.986 37.1344 158.011 38.2203C159.036 39.3061 160.441 39.849 162.227 39.849C163.175 39.8564 164.112 39.6565 164.974 39.2632C165.836 38.87 166.602 38.2929 167.217 37.5725C168.595 36.057 169.344 34.1589 169.465 31.8783C169.56 30.1489 169.092 28.7465 168.063 27.6712C167.034 26.5959 165.647 26.0582 163.903 26.0582C162.953 26.0465 162.012 26.2399 161.144 26.6252C160.276 27.0105 159.501 27.5785 158.872 28.2906C157.487 29.781 156.734 31.6571 156.615 33.9189Z" fill="#F6F6F6" />
                            <path d="M180.039 46.8589L185.913 19.0005H195.814L189.912 46.8589H180.039ZM187.101 10.6996C187.189 9.28992 187.806 7.96545 188.829 6.99123C189.851 6.01702 191.204 5.46493 192.616 5.44547C193.307 5.41994 193.995 5.53938 194.637 5.79607C195.279 6.05277 195.86 6.44103 196.342 6.93586C196.817 7.43304 197.179 8.02643 197.404 8.6758C197.629 9.32517 197.712 10.0154 197.647 10.6996C197.569 12.1355 196.944 13.4872 195.901 14.4771C194.858 15.4671 193.476 16.0201 192.038 16.0228C191.356 16.0373 190.679 15.9044 190.053 15.633C189.427 15.3617 188.868 14.9584 188.412 14.4507C187.943 13.9521 187.582 13.3609 187.354 12.715C187.126 12.069 187.036 11.3825 187.089 10.6996H187.101Z" fill="#F6F6F6" />
                            <path d="M233.328 4.1061L224.313 46.8684H215.044L215.817 43.2556C214.483 44.7245 212.883 45.9269 211.101 46.7992C209.423 47.5981 207.586 48.0087 205.727 48.0003C202.526 48.0003 199.966 46.7908 198.046 44.3718C196.126 41.9528 195.264 38.8295 195.461 35.0019C195.719 30.1806 197.316 26.1905 200.253 23.0316C203.19 19.8726 206.745 18.291 210.918 18.2868C212.667 18.2549 214.395 18.6579 215.949 19.4597C217.567 20.3703 218.963 21.6277 220.037 23.1416L220.078 22.343C220.109 21.7896 220.166 21.2142 220.254 20.6168C220.342 20.0193 220.449 19.4093 220.568 18.7836L223.672 4.10925L233.328 4.1061ZM205.658 33.7567C205.559 35.5427 206.025 36.9775 207.054 38.0612C208.083 39.145 209.489 39.6869 211.271 39.6869C212.219 39.6933 213.158 39.4933 214.022 39.1008C214.886 38.7082 215.654 38.1325 216.273 37.4135C217.661 35.8959 218.415 33.9967 218.537 31.7161C218.629 29.9888 218.157 28.5865 217.119 27.509C216.081 26.4316 214.697 25.8897 212.965 25.8834C212.011 25.8683 211.064 26.0587 210.189 26.4417C209.315 26.8248 208.533 27.3914 207.897 28.1033C206.513 29.5937 205.765 31.474 205.652 33.7441L205.658 33.7567Z" fill="#F6F6F6" />
                            <path d="M250.228 43.1308C248.919 44.6069 247.333 45.8109 245.559 46.6744C243.881 47.4761 242.045 47.8909 240.185 47.8881C236.984 47.8881 234.424 46.6838 232.504 44.2753C230.584 41.8668 229.725 38.7403 229.929 34.8959C230.186 30.0747 231.788 26.0751 234.733 22.8973C237.678 19.7195 241.229 18.1285 245.386 18.1243C247.167 18.0897 248.928 18.5027 250.508 19.3254C252.101 20.2271 253.452 21.4999 254.448 23.0357L255.297 19.0078H264.62L258.718 46.8662H249.562L250.228 43.1308ZM239.88 33.918C239.786 35.6998 240.252 37.1336 241.28 38.2194C242.307 39.3052 243.712 39.8481 245.496 39.8481C246.443 39.8553 247.381 39.6553 248.243 39.2621C249.105 38.8688 249.87 38.2919 250.486 37.5717C251.863 36.0561 252.613 34.158 252.734 31.8774C252.824 30.148 252.357 28.7457 251.332 27.6703C250.307 26.595 248.919 26.0573 247.169 26.0573C246.219 26.0457 245.278 26.2391 244.41 26.6243C243.541 27.0096 242.767 27.5777 242.138 28.2897C240.756 29.7801 240.004 31.6562 239.88 33.918Z" fill="#F6F6F6" />
                            <path d="M263.1 46.8588L269.002 19.0004H278.435L277.51 23.3678C279.135 21.5274 280.723 20.1942 282.274 19.3683C283.812 18.5386 285.532 18.1062 287.28 18.1106C290.166 18.1106 292.367 18.8883 293.883 20.4436C295.398 21.999 296.077 24.1728 295.92 26.9649C295.771 28.5703 295.52 30.1645 295.169 31.7379C295.14 31.9392 295.112 32.1058 295.087 32.2347L291.974 46.8588H282.132L285.239 32.1027C285.333 31.6813 285.418 31.2663 285.484 30.8607C285.545 30.5141 285.587 30.1646 285.61 29.8136C285.673 28.5999 285.403 27.6786 284.793 27.0561C284.183 26.4335 283.252 26.1128 282 26.1128C281.219 26.0846 280.442 26.2367 279.728 26.5573C279.015 26.8779 278.385 27.3585 277.888 27.9616C276.856 29.1942 276.058 31.1615 275.492 33.8635L272.81 46.8557L263.1 46.8588Z" fill="#F6F6F6" />
                            <path d="M60.4049 0.145108L56.3393 8.63154L38.3351 0.000473022L34.2664 8.4869L18.6142 0.984636L0 39.8166L15.6554 47.3188L19.721 38.8356L37.9547 47.5735L42.0202 39.0871L57.4492 46.4793L76.0603 7.64738L60.4049 0.145108Z" fill="#F6F6F6" />
                            <path d="M12.7534 45.9272L11.1938 45.1757L17.6899 31.7087L35.669 40.802L40.0458 31.8754L59.8768 41.4089L59.1253 42.9684L40.8445 34.1802L36.4519 43.1351L18.4823 34.0512L12.7534 45.9272Z" fill="#F6F6F6" />
                        </svg>

                        <button onClick={() => setOpen(!open)}>
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 text-white"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                strokeWidth={2}
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="M4 6h16M4 12h8m-8 6h16"
                                />
                            </svg>
                        </button>
                    </div>

                    <div className="flex-1">
                        <ul className="pt-2 pb-4 space-y-1 ">
                            <li className="rounded-sm" onClick={() => navigate('/')}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md">
                                    <ImHome color='white' style={{ fontSize: "24px" }} />
                                    <span className="text-gray-100 text-lg ">{!open ? "Dashboard" : ""}</span>
                                </div>
                            </li>
                            <li className="rounded-sm" onClick={() => navigate('/playersprofile')}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >

                                    <FaUserAlt color='White' style={{ fontSize: "24px" }} />
                                    <span className="text-gray-100 text-lg ">{!open ? "User Profile" : ""}</span>
                                </div>
                            </li>
                            <li className="rounded-sm" onClick={() => navigate('/matchprofile')}>
                                <div

                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >


                                    <FaRegFutbol color='White' style={{ fontSize: "24px" }} />

                                    <span className="text-gray-100 text-lg ">{!open ? "Match Profile" : ""}</span>
                                </div>
                            </li>
                            <li className="rounded-sm" onClick={() => navigate('/groundprofile')}>
                                <div

                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >

                                    <TbSoccerField color='white' style={{ fontSize: "28px" }} />
                                    <span className="text-gray-100 text-lg ">{!open ? "Ground Profile" : ""}</span>
                                </div>
                            </li>

                            <li className="rounded-sm" onClick={() => navigate('/bookings')}>
                                <div

                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >

                                    <TbBrandBooking color='white' style={{ fontSize: "28px" }} />
                                    <span className="text-gray-100 text-lg ">{!open ? "Booking Profile" : ""}</span>
                                </div>
                            </li>

                            <li className="rounded-sm" onClick={() => navigate('/receipts')}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >
                                    <ReceiptLongIcon  style={{ fontSize: "24px"  , color:'white'}} />

                                    <span className="text-gray-100 text-lg ">{!open ? "Receipts" : ""}</span>
                                </div>
                            </li>

                            <li className="rounded-sm" onClick={() => navigate('/paymentverification')}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >
                                    <PaymentIcon  style={{ fontSize: "24px"  , color:'white'}} />

                                    <span className="text-gray-100 text-lg ">{!open ? "Payment Verification" : ""}</span>
                                </div>
                            </li>
                            <li className="rounded-sm">
                                <div
                                className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                onClick={handleToggle}
                                >
                                {openMenu ? (
                                    <IoIosArrowDown style={{ fontSize: '24px', color: 'white' }} />
                                ) : (
                                    <IoIosArrowForward style={{ fontSize: '24px', color: 'white' }} />
                                )}
                                <span className="text-gray-100 text-lg">{!open ? 'Promotions' : ''}</span>
                                </div>
                                {openMenu && (
                                <div className={`${!open ? 'ml-6' : ''} mt-2 space-y-2`}>
                                    <div
                                        className="cursor-pointer flex items-center p-2 rounded-md text-gray-100 space-x-3"
                                        onClick={() => navigate('/promotions/create')}
                                    >
                                        <IoCreate  style={{ fontSize: "24px"  , color:'white'}} />
                                        <span className="text-lg ">{!open ? "Create" : ""}</span>
                                    </div>
                                    <div
                                        className="cursor-pointer flex items-center p-2 rounded-md text-gray-100 space-x-3"
                                        onClick={() => navigate('/promotions/view-all')}
                                    >
                                        <BiSolidDiscount  style={{ fontSize: "24px"  , color:'white'}} />
                                        <span className="text-lg ">{!open ? "View All" : ""}</span>
                                    </div>
                                </div>
                                )}
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className='pt-2 pb-4 space-y-1' >
                            <li className="rounded-sm" onClick={() => navigate('/updatepassword')}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >
                                    <MdPassword color='white' style={{ fontSize: "24px" }} />

                                    <span className="text-gray-100 text-lg ">{!open ? "Change Password" : ""}</span>
                                </div>
                            </li>
                            <li className="rounded-sm" onClick={() => handleLogout()}>
                                <div
                                    className="flex items-center cursor-pointer p-2 space-x-3 rounded-md"
                                >
                                    <BsLockFill color='White' style={{ fontSize: "24px" }} />
                                    <span className="text-gray-100 text-lg ">
                                        {!open ? "Logout" : ""}
                                    </span>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
    );
}


export default SideNav
